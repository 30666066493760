import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  CreateUserAPI,
  UpdateUserAPI,
  GetAllDepartment,
} from "../../../lib/api";
import { RouteNavigation } from "../../../Routes";
import Cookies from "js-cookie";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";

const CreateUser = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const UserData = location.state && location.state.userData;
  const UserId = UserData?.UserID;
  const [isLoading, setIsLoading] = useState(false);
  const { data } = useQuery({
    queryKey: ["GetAllDepartment"],
    queryFn: () => GetAllDepartment(),
  });
  /* Validation Schema */
  const formSchema = yup.object().shape({
    username: yup.string().required("Account Name required"),
    userType: yup.string().required("User type required"),
    depId: yup.string().required("Department is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(4, "Password too short"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password")], "Passwords do not match"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
    defaultValues: {
      username: UserData?.Username || "",
      depId: UserData?.DepID || "",
      password: UserData?.Password || "",
      confirmPassword: UserData?.Password || "",
      userType: UserData?.UserType || "",
      tokenResetAllowed: UserData?.TokenResetAllowed === true ? true : false,
      status: UserData?.Status === true ? true : false,
    },
  });
  const mutate = useMutation({
    mutationFn: (data) => UpdateUserAPI(data, UserId),
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      toast.success("Update User Successful", {
        position: "top-right",
        closeOnClick: true,
      });
      navigate(RouteNavigation.users);
    },
    onError: () => {
      toast.error("Update User Failed", {
        position: "top-right",
        closeOnClick: true,
      });
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });
  const handleUserSubmit = async (data) => {
    setIsLoading(true);
    const dataResponse = await CreateUserAPI(data);
    try {
      if (dataResponse.status === 201) {
        toast.success("Create User Successful", {
          position: "top-right",
          closeOnClick: true,
        });
        navigate(RouteNavigation.users);
      } else {
        toast.error("Create User Failed " + dataResponse.error || "", {
          position: "top-right",
          closeOnClick: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const CompanyId = Cookies.get("CompanyId");
    if (UserData) {
      const updatedData = {
        companyId: CompanyId,
        depId: data.depId,
        username: data.username,
        password: data.password,
        tokenResetAllowed: data.tokenResetAllowed,
        userType: data.userType,
        status: data.status,
      };
      mutate.mutate(updatedData);
    } else {
      const updatedData = {
        companyId: CompanyId,
        depId: data.depId,
        username: data.username,
        password: data.password,
        tokenResetAllowed: data.tokenResetAllowed,
        userType: data.userType,
        status: data.status,
      };
      handleUserSubmit(updatedData);
    }
  };

  return (
    <div className="py-5">
      <h4 className="text-3xl font-bold mb-5">
        {UserData ? "Update" : "Create"} User
      </h4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 w-full">
            <div>
              <label>User Name</label>
              <input
                {...register("username")}
                type="text"
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 focus:outline-none focus:ring-2"
              />
              <p className="text-red-500 ml-5">{errors.username?.message}</p>
            </div>
            <div>
              <label>Department Name</label>
              <select
                {...register("depId", {
                  required: true,
                })}
                className="border-[1px] p-2 w-full h-12 mt-2 rounded-lg focus:outline-none focus:ring-2"
              >
                {data?.data?.department?.map((dept, index) => {
                  return (
                    <option key={index + 1} value={dept.DepID}>
                      {dept.DepName}
                    </option>
                  );
                })}
              </select>
              <p className="text-red-500 ml-5">{errors.depId?.message}</p>
            </div>
            <div>
              <label>Password</label>
              <input
                type="password"
                {...register("password")}
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 focus:outline-none focus:ring-2"
                placeholder=""
              />
              <p className="text-red-500 ml-5">{errors.password?.message}</p>
            </div>

            <div>
              <label>Confirm Password</label>
              <input
                type="password"
                {...register("confirmPassword")}
                className="h-10 border mt-1 rounded px-4 w-full bg-gray-50 focus:outline-none focus:ring-2"
                placeholder=""
              />
              <p className="text-red-500 ml-5">
                {errors.confirmPassword?.message}
              </p>
            </div>
            <div className="grid w-full">
              <label>Type</label>
              <select
                {...register("userType")}
                className="border-[1px] p-2 rounded-lg focus:outline-none focus:ring-2"
              >
                <option>Administrator</option>
                <option>TokenGenerator</option>
                <option>TokenCoordinator</option>
              </select>
              <p className="text-red-500 ml-5">{errors.userType?.message}</p>
            </div>
            <div className="flex gap-4 mt-2 ml-3 items-center">
              <input {...register("tokenResetAllowed")} type="checkbox" />
              <span>Allow to reset</span>
            </div>
            <div className="flex gap-4 mt-2 ml-3 items-center">
              <input {...register("status")} type="checkbox" />
              <span>Active</span>
            </div>
            <div className="flex gap-5 justify-end">
              <Link to={RouteNavigation.users}>
                <button className="flex items-center justify-center h-8 px-6 bg-gray-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-gray-700">
                  Cancel
                </button>
              </Link>
              <button
                disabled={isLoading}
                className="flex items-center justify-center h-8 px-6 bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
              >
                {isLoading ? "Loading..." : `${UserData ? "Update" : "Create"}`}
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default CreateUser;
