import { useNavigate } from "react-router-dom";
import { GetAllCounter } from "../../../lib/api";
import { useQuery } from "@tanstack/react-query";
import { ShowLoadingAnimation } from "../../../components/common/Animation";
import Cookies from "js-cookie";

const AvailableCounter = () => {
  const navigate = useNavigate();
  const { isLoading, isPending, data } = useQuery({
    queryKey: ["GetAllCounter"],
    queryFn: () => GetAllCounter(),
  });
  const filteredData = data?.data?.counters?.filter(
    (item) => item.Status === true
  );
  if (filteredData?.length === 1) {
    if (Cookies.get("isCustomCall") === "true") {
      navigate(`/custom-counter/${filteredData[0]?.CounterID}`, {
        state: { userCounterData: filteredData[0] },
      });
    } else {
      navigate(`/counter/${filteredData[0]?.CounterID}`, {
        state: { userCounterData: filteredData[0] },
      });
    }
  }
  const handleNavigation = (counter) => {
    if (Cookies.get("isCustomCall") === "true") {
      navigate(`/custom-counter/${counter.CounterID}`, {
        state: { userCounterData: counter },
      });
    } else {
      navigate(`/counter/${counter.CounterID}`, {
        state: { userCounterData: counter },
      });
    }
  };

  return (
    <div>
      <div className="py-5 px-4 lg:px-0 max-w-[1024px] m-auto">
        <h4 className="text-3xl font-bold mb-5">Dashboard</h4>
        {isLoading && isPending && (
          <div className="w-44 m-auto">
            <ShowLoadingAnimation />
          </div>
        )}
        <div className="flex flex-wrap gap-5">
          {filteredData?.map((counter, index) => {
            return (
              <div
                key={index + 1}
                onClick={() => handleNavigation(counter)}
                className=" bg-cover bg-center bg-no-repeat mx-auto mt-5 rounded-xl border-4 border-[#3c73f6] hover:shadow-[5px_5px_0_0_rgba(0,0,0,1)] hover:shadow-[#3c73f6] p-10 cursor-pointer text-center transition-all duration-300"
              >
                <p className="text-xl md:text-2xl font-bold border-b-4 border-b-indigo-300 text-black">
                  {counter.CounterName}
                </p>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default AvailableCounter;
