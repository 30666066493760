import Cookies from "js-cookie";
import { Navigate } from "react-router-dom";

export const SuperAdminProtectedPages = ({ children }) => {
  const loggedUserType = Cookies.get("userType");
  let loggedIn;
  if (loggedUserType === "PlaformAdmin") {
    loggedIn = true;
  } else {
    loggedIn = false;
  }
  if (loggedIn) {
    return <Navigate to="/companies" replace />;
  }

  return children;
};

export const AdminProtectedPages = ({ children }) => {
  const loggedUserType = Cookies.get("userType");
  let loggedIn;
  if (loggedUserType === "Administrator") {
    loggedIn = true;
  } else {
    loggedIn = false;
  }
  if (loggedIn) {
    return <Navigate to="/home" replace />;
  }

  return children;
};

export const CoordinatorProtectedPages = ({ children }) => {
  const loggedUserType = Cookies.get("userType");
  let loggedIn;
  if (loggedUserType === "TokenCoordinator") {
    loggedIn = true;
  } else {
    loggedIn = false;
  }
  if (loggedIn) {
    return <Navigate to="/counter" replace />;
  }

  return children;
};
