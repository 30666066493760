import { Outlet } from "react-router-dom";
import Header from "../../../components/common/Header";

const CoordinatorDashboard = () => {
  return (
    <div className="w-full">
      <Header />
      <div className="max-w-[1024px] m-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default CoordinatorDashboard;
