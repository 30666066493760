import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { UserLogin, GetUserByToken } from "../../lib/api";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

function Login() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    const userToken = Cookies.get("userToken");
    if (userToken) {
      const redirectToPage = async () => {
        try {
          const UserResponse = await GetUserByToken();
          if (UserResponse.status === 200) {
            const userType = UserResponse.data.user.UserType;
            if (userType === "PlatformAdmin") {
              navigate("/companies");
            } else if (userType === "Administrator") {
              navigate("/home");
            } else if (userType === "TokenCoordinator") {
              navigate("/counter");
            }
          }
        } catch (error) {
          return error;
        }
      };
      redirectToPage();
    }
  }, [navigate]);

  const onSubmit = async (data) => {
    setIsLoading(true);
    const dataResponse = await UserLogin(data);
    try {
      if (dataResponse.status === 200) {
        Cookies.set("userToken", dataResponse?.data?.token, { expires: null });
        const UserResponse = await GetUserByToken();
        if (UserResponse.status === 200) {
          const userType = UserResponse.data.user.UserType;
          if (userType === "PlaformAdmin") {
            navigate("/companies");
          } else if (userType === "Administrator") {
            navigate("/home");
          } else if (userType === "TokenCoordinator") {
            navigate("/counter");
          }
        }
        toast.success("Login Sucessful", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        toast.error("Login Failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-screen h-screen select-none">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="border-2 p-5 rounded-xl w-96 bg-white shadow-[rgba(13,_38,_76,_0.19)_0px_9px_20px]">
          <h4 className="text-center text-2xl font-bold mb-5 uppercase">
            Sign in
          </h4>
          <div className="flex flex-col">
            <label className="font-semibold text-xs" htmlFor="usernameField">
              Username
            </label>
            <input
              className="flex items-center h-12 px-4 w-full bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
              autoComplete="username"
              {...register("username", { required: true })}
            />
            {errors.username && (
              <span className="text-red-600">This field is required</span>
            )}
            <label
              className="font-semibold text-xs mt-5"
              htmlFor="passwordField"
            >
              Password
            </label>
            <input
              className="flex items-center h-12 px-4 w-full bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
              type="password"
              autoComplete="current-password"
              {...register("password", { required: true })}
            />
            {errors.password && (
              <span className="text-red-600">This field is required</span>
            )}
            <button
              type="submit"
              disabled={isLoading}
              className="flex items-center justify-center h-12 px-6 w-full bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
            >
              {isLoading ? "Logging in...." : "Log In"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
