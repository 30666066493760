import { FaUser } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";
import { useNavbarContext } from "../../../lib/context/NavBar/navbarContext";
import { RxHamburgerMenu } from "react-icons/rx";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const Header = () => {
  const { isNavbarOpen, toggleNavbar } = useNavbarContext();
  const navigate = useNavigate();
  const handleLogout = () => {
    const allCookies = Cookies.get();
    Object.keys(allCookies).forEach((cookieName) => {
      Cookies.remove(cookieName);
    });
    navigate("/");
  };
  return (
    <div className="bg-[#3C73F6] w-full py-3">
      <div
        className={`${
          isNavbarOpen ? "justify-end" : "justify-between"
        } max-w-[1024px] m-auto flex items-center px-4 lg:px-0`}
      >
        {isNavbarOpen ? (
          ""
        ) : (
          <div className="text-white">
            <RxHamburgerMenu onClick={(e) => toggleNavbar()} />
          </div>
        )}
        <div className="flex group select-none cursor-pointer relative">
          <div className="w-10 h-10 rounded-full bg-white flex justify-center items-center -mr-9 border-2 border-[#3C73F6] z-10">
            <FaUser color={"#3C73F6"} />
          </div>
          <div className="rounded-full h-[2.3rem] mt-[1px] w-full bg-white text-base pl-10 pr-5 flex gap-2 items-center text-[#3C73F6] justify-around">
            {Cookies.get("userName")}
            <IoIosArrowDown
              className="group-hover:rotate-180 transition-all duration-300"
              color={"#3C73F6"}
            />
          </div>
          <div
            onClick={handleLogout}
            className="group-hover:flex hidden h-[2.3rem] bg-white rounded-full border border-[#3C73F6] absolute top-10 z-10 left-0 right-0 text-[#3C73F6] px-5 items-center justify-around"
          >
            <div className="size-10 absolute -top-[1.2] -left-1 rounded-full bg-white flex justify-center items-center border-[1px] border-[#3C73F6] z-10">
              <IoLogOutOutline size={"20px"} color={"#3C73F6"} />
            </div>
            <span className="pl-5">Logout</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
