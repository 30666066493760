import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { CreateCompanyAPI, UpdateCompanyAPI } from "../../../lib/api";
import { RouteNavigation } from "../../../Routes";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const CreateCompany = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const isCompany = location.state && location.state.CompanyData;

  /* Validation Schema */
  const formSchema = yup.object().shape({
    companyName: yup.string().required("Required"),
    licenseKey: yup.string().required("Required"),
    country: yup.string().required("Required"),
    address: yup.string().required("Required"),
    email: yup.string().required("Required"),
    username: yup.string().required("Required"),
    password: yup
      .string()
      .required("Password is required")
      .min(4, "Password too short"),
    confirmPassword: yup
      .string()
      .required("Confirm Password is required")
      .oneOf([yup.ref("password")], "Passwords do not match"),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: !isCompany ? yupResolver(formSchema) : "",
    defaultValues: {
      companyName: isCompany?.CompanyName || "",
      licenseKey: isCompany?.LicenseKey || "",
      country: isCompany?.Country || "",
      address: isCompany?.Address || "",
      email: isCompany?.Email || "",
      username: "",
      password: "",
    },
  });
  const companyId = isCompany?.CompanyID;
  const mutate = useMutation({
    mutationFn: (data) => UpdateCompanyAPI(data, companyId),
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      toast.success("Update Company Successful", {
        position: "top-right",
        closeOnClick: true,
      });
      navigate(RouteNavigation.companies);
    },
    onError: () => {
      toast.error("Update Company Failed", {
        position: "top-right",
        closeOnClick: true,
      });
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleSecondSubmit = async (data) => {
    setIsLoading(true);
    const dataResponse = await CreateCompanyAPI(data);
    try {
      if (dataResponse.status === 201) {
        toast.success("Create Company Successful", {
          position: "top-right",
          closeOnClick: true,
        });
        navigate(RouteNavigation.companies);
      } else {
        toast.error("Create Company Failed " + dataResponse.error || "", {
          position: "top-right",
          closeOnClick: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const onSubmit = async (data) => {
    // Conditionally call the appropriate submit handler based on some criteria
    if (isCompany) {
      const updatedData = {
        companyName: data.companyName,
        licenseKey: data.licenseKey,
        status: true,
        country: data.country,
        address: data.address,
        email: data.email,
        isExpired: false,
      };
      mutate.mutate(updatedData);
    } else {
      const updatedData = {
        CompanyName: data.companyName,
        LicenseKey: data.licenseKey,
        Country: data.country,
        Address: data.address,
        Email: data.email,
        username: data.username,
        password: data.password,
      };
      handleSecondSubmit(updatedData);
    }
  };
  return (
    <div className="px-5 py-5 w-full">
      <div className="cd-card px-4 sm:px-16">
        <h2 className="text-center font-bold text-lg mb-8">
          {isCompany ? "Update" : "Register"} Company
        </h2>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="grid grid-cols sm:grid-cols-2 gap-2 font-poppins">
              <div>
                <span className="font-semibold">Company Name</span>
                <input
                  {...register("companyName", {
                    required: "Required Field",
                  })}
                  className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-gray-800"
                  placeholder="Company Name"
                />
                {errors.companyName && (
                  <p className="text-red-600 ml-2">
                    {errors.companyName.message}
                  </p>
                )}
              </div>
              <div>
                <span className="font-semibold">License Key</span>
                <input
                  {...register("licenseKey")}
                  className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-gray-800"
                  placeholder="License Key"
                />
                {errors.licenseKey && (
                  <p className="text-red-600 ml-2">
                    {errors.licenseKey.message}
                  </p>
                )}
              </div>
              <div>
                <span className="font-semibold">Country</span>
                <input
                  {...register("country")}
                  className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-gray-800"
                  placeholder="Country"
                />
                {errors.country && (
                  <p className="text-red-600 ml-2">{errors.country.message}</p>
                )}
              </div>
              <div>
                <span className="font-semibold">Address</span>
                <input
                  {...register("address")}
                  className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-gray-800"
                  placeholder="Address"
                />
                {errors.address && (
                  <p className="text-red-600 ml-2">{errors.address.message}</p>
                )}
              </div>
              <div>
                <span className="font-semibold">Email</span>
                <input
                  {...register("email")}
                  type="email"
                  className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-gray-800"
                  placeholder="Email"
                />
                {errors.email && (
                  <p className="text-red-600 ml-2">{errors.email.message}</p>
                )}
              </div>
              {!isCompany && (
                <div>
                  <span className="font-semibold">User Name</span>
                  <input
                    {...register("username")}
                    className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-gray-800"
                    placeholder="User Name"
                  />
                  {errors.username && (
                    <p className="text-red-600 ml-2">
                      {errors.username.message}
                    </p>
                  )}
                </div>
              )}
              {!isCompany && (
                <div>
                  <span className="font-semibold">Password</span>
                  <input
                    {...register("password")}
                    type="password"
                    className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-gray-800"
                    placeholder="Password"
                  />
                  {errors.password && (
                    <p className="text-red-600 ml-2">
                      {errors.password.message}
                    </p>
                  )}
                </div>
              )}
              {!isCompany && (
                <div>
                  <span className="font-semibold">Confirm Password</span>
                  <input
                    {...register("confirmPassword")}
                    type="password"
                    className="block border border-grey-light w-full p-3 rounded mb-4 focus:outline-gray-800"
                    placeholder="Confirm Password"
                  />
                  {errors.confirmPassword && (
                    <p className="text-red-600 ml-2">
                      {errors.confirmPassword.message}
                    </p>
                  )}
                </div>
              )}
            </div>
            <div className="text-center mt-6">
              <button
                type="submit"
                disabled={isLoading}
                className="w-full md:w-[250px] bg-black text-white border-2 border-gray-300 rounded-md p-2"
              >
                {isLoading
                  ? "Loading..."
                  : `${isCompany ? "Update" : "Register"}`}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CreateCompany;
