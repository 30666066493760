import axios from "axios";
import Cookies from "js-cookie";

export const GetAllCompany = (data) => {
  let companyUrl;
  if (data) {
    companyUrl = `company?searchString=${data}`;
  } else {
    companyUrl = `company`;
  }
  try {
    const response = axios.get(companyUrl, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (error) {
    return error;
  }
};

export const CreateCompanyAPI = (data) => {
  const CreateCompanyURL = "company/register";
  try {
    const response = axios.post(CreateCompanyURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};

export const UpdateCompanyAPI = async (data, companyId) => {
  if (!companyId) return;
  const UpdateCompanyURL = `company/update/${companyId}`;
  try {
    const response = await axios.put(UpdateCompanyURL, data, {
      headers: {
        Authorization: `Bearer ${Cookies.get("userToken")}`,
      },
    });
    return response;
  } catch (err) {
    return err;
  }
};
