import GenericRoutes from "./Routes";
import {AuthProvider} from "./lib/context/Auth/AuthContext";
import { NavbarProvider } from "./lib/context/NavBar/navbarContext";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <AuthProvider>
      <NavbarProvider>
        <GenericRoutes />
        <ToastContainer />
      </NavbarProvider>
    </AuthProvider>
  );
}

export default App;
