import { Outlet } from "react-router-dom";
import Navbar from "../../../components/common/NavBar";
import Header from "../../../components/common/Header";
import { useNavbarContext } from "../../../lib/context/NavBar/navbarContext";

const SuperAdminDashboard = () => {
  const { isNavbarOpen } = useNavbarContext();
  return (
    <div className="flex gap-20">
      <Navbar />
      <div className="w-full">
        <Header />
        <div
          className={`${
            isNavbarOpen ? "sm:pl-[18rem]" : ""
          } relative transition-all duration-300 delay-0 px-8`}
        >
          <Outlet />
          {isNavbarOpen ? (
            <div className="h-screen absolute sm:hidden bottom-0 left-0 right-0 top-0 w-full overflow-hidden bg-black bg-fixed opacity-50"></div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default SuperAdminDashboard;
