import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { useMutation } from "@tanstack/react-query";
import { GetCompanySettings, UpdateCompanySettings } from "../../../lib/api";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { ShowLoadingAnimation } from "../../../components/common/Animation";

const Settings = () => {
  const { isLoading, data, refetch } = useQuery({
    queryKey: ["GetCompanySettings"],
    queryFn: () => GetCompanySettings(),
  });
  const Company = data?.data?.companySettings;
  const [isLoadingData, setIsLoadingData] = useState(false);
  const { register, setValue, handleSubmit, watch } = useForm();
  const isMobileNumberChecked = watch("customerMobileNumber");
  const isCustomerNameChecked = watch("customerName");
  useEffect(() => {
    if (data) {
      setValue("token", data?.data?.maxToken || "");
      setValue(
        "customerName",
        Company?.CollectCustomerName === true ? true : false
      );
      setValue(
        "customerMobileNumber",
        Company?.CollectCustomerMobileNumber === true ? true : false
      );
      setValue(
        "printCustomerName",
        Company?.PrintCustomerName === true ? true : false
      );
      setValue(
        "printCustomerMobileNumber",
        Company?.PrintCustomerMobileNumber === true ? true : false
      );
    }
  }, [
    data,
    setValue,
    Company?.CollectCustomerName,
    Company?.CollectCustomerMobileNumber,
    Company?.PrintCustomerName,
    Company?.PrintCustomerMobileNumber,
  ]);
  useEffect(() => {
    if (!isMobileNumberChecked) {
      setValue("printCustomerMobileNumber", false);
    }
    if (!isCustomerNameChecked) {
      setValue("printCustomerName", false);
    }
  }, [isMobileNumberChecked, isCustomerNameChecked, setValue]);
  const mutate = useMutation({
    mutationFn: (updatedData) => UpdateCompanySettings(updatedData),
    onMutate: () => {
      setIsLoadingData(true);
    },
    onSuccess: () => {
      toast.success("Settings Updated", {
        position: "top-right",
        closeOnClick: true,
      });
      refetch();
    },
    onError: () => {
      toast.error("Failed to update settings", {
        position: "top-right",
        closeOnClick: true,
      });
    },
    onSettled: () => {
      setIsLoadingData(false);
    },
  });
  const onSubmit = async (data) => {
    const DepId = Cookies.get("DepId");
    const CompanyId = Cookies.get("CompanyId");
    const updatedData = {
      companyId: CompanyId,
      depId: DepId,
      maximumToken: data.token,
      collectCustomerName: data.customerName,
      printCustomerName: data.printCustomerName,
      collectCustomerMobileNumber: data.customerMobileNumber,
      printCustomerMobileNumber: data.printCustomerMobileNumber,
    };
    mutate.mutate(updatedData);
  };
  return (
    <div className="py-5">
      <h4 className="text-3xl font-bold mb-5">Settings</h4>
      {isLoading && (
        <div className="w-44 m-auto">
          <ShowLoadingAnimation />
        </div>
      )}
      {!isLoading && (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col w-full md:w-96 mt-5"
        >
          <label className="font-semibold">Token Limit</label>
          <input
            {...register("token")}
            className="flex items-center h-12 px-4 w-full bg-gray-200 mt-2 rounded focus:outline-none focus:ring-2"
            type="text"
          />
          <div className="flex gap-4 mt-2">
            <div className="flex gap-2">
              <input {...register("customerMobileNumber")} type="checkbox" />
              <span>Mobile Number</span>
            </div>
            <div className="flex gap-2">
              <input
                {...register("printCustomerMobileNumber")}
                disabled={!isMobileNumberChecked}
                type="checkbox"
              />
              <span>Print</span>
            </div>
          </div>
          <div className="flex gap-4 mt-2">
            <div className="flex gap-2">
              <input {...register("customerName")} type="checkbox" />
              <span>Customer Name</span>
            </div>
            <div className="flex gap-2">
              <input
                {...register("printCustomerName")}
                disabled={!isCustomerNameChecked}
                type="checkbox"
              />
              <span>Print</span>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={isLoadingData}
              className="flex items-center justify-center h-8 px-6 bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
            >
              {isLoadingData ? "Saving..." : "Save"}
            </button>
          </div>
        </form>
      )}
    </div>
  );
};

export default Settings;
