import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useQuery, useMutation } from "@tanstack/react-query";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  UploadImage,
  CreateAdvertisementAPI,
  UpdateAdvertisementAPI,
  GetAllDepartment,
} from "../../../lib/api";
import { RouteNavigation } from "../../../Routes";
import Cookies from "js-cookie";
import { constant } from "../../../lib/constant";

const CreateAdvertisement = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const AdvertisementData = location.state && location.state.GetAdvertisement;
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(
    AdvertisementData?.AdvFileUrl || null
  );
  const [imageError, setImageError] = useState(null);
  const { data } = useQuery({
    queryKey: ["GetAllDepartment"],
    queryFn: () => GetAllDepartment(),
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      advName: AdvertisementData?.AdvName || "",
      advOrder: AdvertisementData?.AdvOrder || "",
      depId: AdvertisementData?.DepID || "",
      image: AdvertisementData?.AdvFileUrl || imageSrc,
      status: AdvertisementData?.Status === true ? true : false,
    },
  });
  const AdvId = AdvertisementData?.AdvID;
  const mutate = useMutation({
    mutationFn: (data) => UpdateAdvertisementAPI(data, AdvId),
    onMutate: () => {
      setIsLoading(true);
    },
    onSuccess: () => {
      toast.success("Update Advertisement Successful", {
        position: "top-right",
        closeOnClick: true,
      });
      navigate(RouteNavigation.advertisements);
    },
    onError: () => {
      toast.error("Update Advertisement Failed", {
        position: "top-right",
        closeOnClick: true,
      });
    },
    onSettled: () => {
      setIsLoading(true);
    },
  });
  const handleAdvertisementSubmit = async (data) => {
    const dataResponse = await CreateAdvertisementAPI(data);
    try {
      if (dataResponse.status === 201) {
        toast.success("Create Advertisement Successful", {
          position: "top-right",
          closeOnClick: true,
        });
        navigate(RouteNavigation.advertisements);
      } else {
        toast.error("Create Advertisement Failed " + dataResponse.error || "", {
          position: "top-right",
          closeOnClick: true,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };
  const handleFileChange = async (event) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("Image", file);
      const dataResponse = await UploadImage(formData);
      if (dataResponse.status === 200) {
        setImageSrc(dataResponse?.data?.image1);
      }
    }
  };
  const submit = async (data) => {
    if (imageSrc === null) {
      setImageError("Image is Required");
    } else {
      setIsLoading(true);
      const currentDate = new Date().toISOString();
      const userId = Cookies.get("UserID");
      const CompanyId = Cookies.get("CompanyId");
      const updatedData = {
        companyId: CompanyId,
        depId: data.depId,
        advName: data.advName,
        advOrder: data.advOrder,
        advFileUrl: imageSrc || AdvertisementData?.AdvFileUrl,
        advModifiedDate: currentDate,
        advModifiedUserId: userId,
        status: data.status,
      };
      if (AdvertisementData) {
        mutate.mutate(updatedData);
      } else {
        handleAdvertisementSubmit(updatedData);
      }
    }
  };
  return (
    <div className="py-5">
      <h4 className="text-3xl font-bold mb-5">
        {AdvertisementData ? "Update" : "Create"} Advertisement
      </h4>
      <form onSubmit={handleSubmit(submit)} className="flex flex-col mt-5">
        <div className="flex gap-5 flex-col md:flex-row">
          <div className="w-full md:w-fit">
            <label className="font-semibold">Advertisement Name</label>
            <input
              {...register("advName", { required: "Name is Required" })}
              className="flex items-center w-full md:w-72 h-12 px-4 bg-gray-200 mt-2 rounded focus:outline-none ring-1 focus:ring-2"
              type="text"
            />
            {errors.advName && (
              <p className="text-red-600 ml-1">{errors.advName.message}</p>
            )}
          </div>
          <div>
            <label>Department Name</label>
            <select
              {...register("depId", {
                required: true,
              })}
              className="border-[1px] p-2 w-full h-12 mt-2 rounded-lg focus:outline-none focus:ring-2 bg-gray-200"
            >
              {data?.data?.department?.map((dept, index) => {
                return (
                  <option key={index + 1} value={dept.DepID}>
                    {dept.DepName}
                  </option>
                );
              })}
            </select>
            <p className="text-red-500 ml-5">{errors.depId?.message}</p>
          </div>
          <div>
            <label className="font-semibold">Order</label>
            <input
              {...register("advOrder", { required: "Order is Required" })}
              className="flex items-center h-12 px-4 w-20 bg-gray-200 mt-2 rounded focus:outline-none ring-1 focus:ring-2"
              type="text"
            />
            {errors.advOrder && (
              <p className="text-red-600 ml-1">{errors.advOrder.message}</p>
            )}
          </div>
        </div>
        <div className="mt-4">
          {imageSrc && (
            <img
              src={constant.image_render_url + imageSrc}
              alt="image-preview"
            />
          )}
          <input
            type="file"
            accept="image/*"
            multiple
            onChange={handleFileChange}
            className="cursor-pointer relative block w-full h-full z-50"
          />
          {imageError && <p className="text-red-600">{imageError}</p>}
        </div>
        <div className="flex gap-4 mt-2 ">
          <input {...register("status")} type="checkbox" />
          <span>Active</span>
        </div>
        <div className="flex gap-5 justify-end">
          <Link to={RouteNavigation.advertisements}>
            <button className="flex items-center justify-center h-8 px-6 bg-gray-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-gray-700">
              Cancel
            </button>
          </Link>
          <button
            type="submit"
            disabled={isLoading}
            className="flex items-center justify-center h-8 px-6 bg-blue-600 mt-8 rounded font-semibold text-sm text-blue-100 hover:bg-blue-700"
          >
            {isLoading
              ? "Loading..."
              : `${AdvertisementData ? "Update" : "Create"}`}
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreateAdvertisement;
