import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { CiWarning } from "react-icons/ci";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { DeleteAllToken } from "../../../lib/api";
import Cookies from "js-cookie";

export const TokenReset = () => {
  const [open, setOpen] = useState(false);
  let [dialog, setDialog] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleClickOpen = () => {
    setDialog(1);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirmReset = async () => {
    setDialog(2);
  };

  const onSubmit = async () => {
    try {
      const getCurrentToken = await DeleteAllToken();
      if (getCurrentToken.status === 200) {
        toast.success("Token Reset" || "", {
          position: "top-right",
          closeOnClick: true,
        });
      } else {
        toast.failed("Token Failed To Reset" || "", {
          position: "top-right",
          closeOnClick: true,
        });
      }
    } finally {
      setOpen(false);
    }
    reset();
  };

  return (
    <React.Fragment>
      <Button
        className="bg-gradient-to-r from-cyan-500 to-blue-500 hover:bg-gradient-to-bl font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
        style={{ color: "white", textTransform: "capitalize" }}
        variant="string"
        onClick={handleClickOpen}
      >
        Reset Counter
      </Button>

      {dialog === 1 && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle className="flex justify-center">
            <CiWarning size={"50px"} color="red" />
          </DialogTitle>
          <DialogContent>
            <DialogContentText>Do you want to reset token?</DialogContentText>
          </DialogContent>
          <DialogActions>
            <button className="p-2" onClick={handleClose}>
              Close
            </button>
            <button className="p-2" onClick={handleConfirmReset} autoFocus>
              Continue
            </button>
          </DialogActions>
        </Dialog>
      )}
      {dialog === 2 && (
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle className="flex justify-center">
            {"Please enter your password to reset the counter"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <div class="p-4 md:p-5">
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
                  <div>
                    <input
                      placeholder="Enter password!"
                      {...register("password", {
                        required: "Please enter your password",
                        validate: (value) =>
                          value === Cookies.get("userPassword") ||
                          "Password is wrong",
                      })}
                      type="password"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 focus:outline-none"
                    />
                    {errors.password && (
                      <p className="text-red-600 ml-2 mt-2">
                        {errors.password.message}
                      </p>
                    )}
                  </div>
                  <button
                    type="submit"
                    class="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                  >
                    Reset Counter
                  </button>
                </form>
              </div>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};
