import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { IoMdClose } from "react-icons/io";
import Cookies from "js-cookie";

export default function TokenCallPopUp({
  open,
  setOpen,
  handleTokenCall,
  handleTokenComplete,
  isLoadingCall,
}) {
  const handleClickClose = () => {
    setOpen(false);
    Cookies.set("isHoldToken", false);
  };
  return (
    <React.Fragment>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="select-none flex justify-between items-center"
          >
            What do you want to do with current token?
            <div className="ml-5 cursor-pointer" onClick={handleClickClose}>
              <IoMdClose />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {Cookies.get("isHoldToken") === "true" && (
            <Button
              onClick={() => handleTokenComplete()}
              className="capitalize"
              disabled={isLoadingCall}
            >
              Complete Token
            </Button>
          )}
          <Button
            onClick={() => {
              handleTokenCall(1);
            }}
            className="capitalize"
            disabled={isLoadingCall}
          >
            Hold & Call
          </Button>
          <Button
            onClick={() => {
              handleTokenCall(0);
            }}
            className="capitalize"
            disabled={isLoadingCall}
          >
            Complete & Call
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
