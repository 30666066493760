import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import { useLocation, useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(Cookies.get("userToken") || null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const allowedRoutes = ["/", "*"];
    if (!allowedRoutes.includes(location.pathname)) {
      const storedToken = Cookies.get("userToken");
      if (!storedToken) {
        navigate("/", { replace: true });
      } else if (!token) {
        setToken(storedToken);
      }
    }
  }, [token, location.pathname, navigate, setToken]);

  const login = (userToken) => {
    Cookies.set("userToken", userToken, { expires: 30 });
    setToken(userToken);
  };

  const logout = () => {
    Cookies.remove("userToken");
    setToken(null);
  };

  return (
    <AuthContext.Provider value={{ token, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
